#gear{
    animation: rotation 3s linear infinite;
    transform-origin: center;
    transition: transform .5s ;
    transform-box: fill-box;
    }
    
    #black{
        animation: move-right 5s linear infinite;
    transform-origin: center;
    transition: transform .5s ;
    transform-box: fill-box;
    }
    
    
    #blue{
        animation: move-left 5s linear infinite;
        transform-origin: center;
        transition: transform .5s ;
        transform-box: fill-box;
    }
    
    
    #mouse{
        animation:mouse-move 5s linear infinite;
        transform-origin: center;
        transition: transform .5s ;
        transform-box: fill-box;
    }
    
    
    @keyframes rotation {
    25%{
        transform: rotateZ(180deg);
    }    
    40%{
        transform: rotateZ(180deg);
    
    }
    60%{
        transform: rotateZ(-180deg);
    
    }
    80%{
        transform: rotateZ(-180deg);
    
    }
    100%{
        transform: rotateZ(0);
    
    }
    }
    
    @keyframes move-right {
        0%{
            transform: translateX(0);
    
        }
        25%{
            transform: translateX(500%);
        }    
        40%{
            transform: translateX(500%);
        
        }
        60%{
            transform: translateX(200%);
        
        }
        80%{
            transform: translateX(200%);
        
        }
        100%{
            transform: translateX(0);
        
        }
    }
    
    @keyframes move-left {
        0%{
            transform: translateX(0);
    
        }
        25%{
            transform: translateX(-300%);
        }    
        40%{
            transform: translateX(-300%);
        
        }
        60%{
            transform: translateX(-200%);
        
        }
        80%{
            transform: translateX(-200%);
        
        }
        100%{
            transform: translateX(0);
        
        }
    }
    
    
    @keyframes mouse-move {
        0%{
            transform: translateX(0);
    
        }
        25%{
            transform: translate(100%, 0);
        }    
        40%{
            transform: translate(100%, 0);
        
        }
        60%{
            transform: translate(0, -100%);
        
        }
        80%{
            transform: translate(0, -100%);
        
        }
        100%{
            transform: translate(0);
        
        }
    }