.graph-svg{
    width: 70%; 
 }
 
 
 .shade{
     position: absolute;
     border : 1px solid black;
     width : 15rem;
     height : 120rem;
     background-color: white;
 
 }
 
 .arrow{
 transform: translateY(-20%);
 opacity: 0;
 transition: all 1.5s;
 }
 
.arrow-1{
 transform: translateY(0);
 opacity: 1;
 transition: all 1.5s;

 }
 
 /* Orrange */
 /* #graph > path:nth-child(1) {
     d: path("M 122 146 H 139 V 152 H 122 V 146 Z");
     transition: all 1.5s;
 } */
 
section:hover #graph > path:nth-child(1){
     d: path("M 122.178 84.1919 H 138.854 V 151.855 H 122.178 V 84.1919 Z");
 }
 
 
 /* Cyan  */
/*  
 #graph > path:nth-child(2) {
     d: path("M 154 146 H 170 V 150 H 154 V 146 Z");
     transition: all 1.5s;
 }
  */
section:hover #graph > path:nth-child(2){
     d: path("M 153.605 64.5551 H 170.28 V 149.855 H 153.605 V 64.5551 Z");
 }
 
 
 
 /* Yellow  */
 
 /* #graph > path:nth-child(4) {
     d: path("M 92 146 H 109 V 152 H 92 V 146 Z");
     transition: all 1.5s;
 } */
 
section:hover #graph > path:nth-child(4){
     d: path("M 92.035 103.112 H 108.71 V 151.855 H 92.035 V 103.112 Z");
 }
 
 
 /* Blue  */
 
 /* #graph > path:nth-child(3) {
     d: path("M 62 146 H 79 V 152 H 62 V 146 Z");
     transition: all 1.5s;
 } */
 
section:hover #graph > path:nth-child(3){
     d: path("M 62.2122 91.8892 H 78.5668 V 151.856 H 62.2122 V 91.8892 Z");
 }
 
 /* Black  */
 
 /* #graph > path:nth-child(5) {
     d: path("M 32 146 H 49 V 152 H 32 V 146 Z");
     transition: all 1.5s;
 }
  */
section:hover #graph > path:nth-child(5){
     d: path("M 32.0361 121.955 H 48.6504 V 151.82 H 32.0361 V 121.955 Z");
 }

 .white{
    transform: translate(-140%, 240%);
    transform-origin: center;
    transition: transform 1.5s ;
    transform-box: fill-box;
 }


 .white-1{
    transform: translate(100%, -100%);
    transform-origin: center;
    transition: transform 1.5s ;
    transform-box: fill-box;
 }

 .pie{
    transform: rotateZ(0);
    transform-origin: center;
    transition: transform 1.5s ;
    transform-box: fill-box;
 }

 .pie-1{
    transform: rotateZ(360deg);
    transform-origin: center;
    transition: transform 1.5s ;
    transform-box: fill-box;
 }