section:hover #sand{
    transform: rotateZ(360deg);
    transform-box: fill-box;
    transform-origin: center;
    transition: transform 2s;
}

section:hover #layer{
    transform: translateY(5px);
    transform-box: fill-box;
    transform-origin: center;
    transition: transform 2s;
}


section:hover #dart{
    transform: translateX(-75%);
    transform-box: fill-box;
    transform-origin: center;
    transition: transform 2s;
}


section:hover #gear-1{
    transform: rotateZ(-360deg);
    transform-box: fill-box;
    transform-origin: center;
    transition: transform 2s;
}

section:hover #gear-2{
    transform: rotateZ(360deg);
    transform-box: fill-box;
    transform-origin: center;
    transition: transform 2s;
}

section:hover #gear-3{
    transform: rotateZ(360deg);
    transform-box: fill-box;
    transform-origin: center;
    transition: transform 2s;
}

section:hover #star-1{
    transform: rotateY(360deg);
    transform-box: fill-box;
    transform-origin: center;
    transition: transform 2s;
    perspective: 1000px;
}
section:hover #star-2{
    transform: rotateX(-360deg);
    transform-box: fill-box;
    transform-origin: center;
    transition: transform 2s;
    perspective: 1000px;

}
section:hover #star-3{
    transform: rotateY(360deg);
    transform-box: fill-box;
    transform-origin: center;
    transition: transform 2s;
    perspective: 1000px;

}

.mark{
    opacity: 0;
}

section:hover #checklist > path{
    opacity: 1;
    transition: opacity 2s;
}