@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

html{
  font-size : 16px;
  scroll-behavior: smooth;
}

body{
  font-family: 'Poppins', sans-serif;
  background-color: #f9fafc;
  
}

body::-webkit-scrollbar {
  width: 0.5em;
}
 
body::-webkit-scrollbar-track {
  background-color: #f9fafc ;
  /* box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3); */
}
 
body::-webkit-scrollbar-thumb {
  background-color: #3742fa;;
  border-radius: 30px;
} 

#root{
  max-width: 1500px;
  margin: 0 auto;
}

.App{
  max-width: 1100px
}

nav .inner-nav {
  max-width: 1100px;
}
.btn{
  @apply bg-primary2 px-6 py-2 text-secondary border border-primary2;
}

.btn:hover{
  @apply text-primary2 bg-secondary border border-primary2;
}
nav, .nav{
  background-color: #f9fafc;
}
nav .active {
  @apply text-primary2 border-b-3 border-primary2
}

a{
  transition: all .8s;
}
.main-nav:hover {
  @apply text-primary2 border-b-3 border-primary2
}


ul{
  list-style :disc;
  list-style-position: inside;
}

.form-input{
  @apply bg-gray-300 rounded-sm py-3 px-4 focus:placeholder:text-sm placeholder:text-black outline-primary2
}

.badges{
  opacity: 0;
  position: relative;
  z-index: 20;
}


#plan-badge{
  animation: display-badge .8s ease-in;
  animation-fill-mode:forwards;

}
#design-badge{
  animation: display-badge .8s ease-in 2s;
  animation-fill-mode: forwards;
}

#development-badge{
  animation: display-badge .8s ease-in 4s;
  animation-fill-mode: forwards;
}

#test-badge{
  animation: display-badge .8s ease-in 6s;
  animation-fill-mode: forwards;
}

#review-badge{
animation: display-badge .8s ease-in 8s;
animation-fill-mode: forwards;
}

#last-badge{
  animation: display-badge .8s ease-in 10s;
  animation-fill-mode: forwards;
}


.plan-label{
  animation: display-label 1.5s ease-in .3s;
}

.design-label{
  animation: display-label 1.5s ease-in 2.3s;

}

.development-label{
  animation: display-label 1.5s ease-in 4.3s;
}

.test-label{
  animation: display-label 1.5s ease-in 6.3s;
}

.review-label{
  animation: display-label 1.5s ease-in 8.3s;
}

.last-label{
  animation: display-label 1.5s ease-in 10.3s;
}

@keyframes display-badge {
  from {
      opacity: 0;
      transform: translateY(10%);
  }
  to{
      opacity: 1;
      transform: translateY(0);
  }
}

@keyframes display-label {
  0% {
      opacity: 0;
      transform: translateY(10%);
  }
  30%,80%{
      opacity: 1;
      transform: translateY(0);
  }
  100%{
      opacity: 0;
  }
}

ul{
  color : blue;
}



.timer-block{
  
  box-shadow: 76px 7px 181px -18px rgba(0,0,0,0.75);
  -webkit-box-shadow: 76px 7px 181px -18px rgba(0,0,0,0.75);
  -moz-box-shadow: 76px 7px 181px -18px rgba(0,0,0,0.75);
}

