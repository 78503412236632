.puzzle1{
    transform: translate(0) rotateZ(0);
    transform-box: fill-box;
    transform-origin: center;
    transition: transform 1s;
}


.puzzle3{
    transform: translate(0,) rotateZ(0);
    transform-box: fill-box;
    transform-origin: center;
    transition: transform 1s;
}


.puzzle2{
    transform: translate(0,0);
    transform-box: fill-box;
    transform-origin: center;
    transition: transform 1s;
}

.puzzle4{
    transform: translate(0) rotateZ(0);
    transform-box: fill-box;
    transform-origin: center;
    transition: transform 1s;
}


.puzzle1-move{
    transform: translate(19%,5%) rotateZ(11deg);
    transform-box: fill-box;
    transform-origin: center;
    transition: transform 1s;
}

  
 .puzzle2-move{
    transform: translate(-22%, 5%);
    transform-box: fill-box;
    transform-origin: center;
    transition: transform 1s;
}


  
.puzzle3-move{
    transform: translate(10.5%, -25%) rotateZ(-8deg);
    transform-box: fill-box;
    transform-origin: center;
    transition: transform 1s;
}



.puzzle4-move{
    transform: translate(-24%, -25.5%) rotateZ(-10.5deg);
    transform-box: fill-box;
    transform-origin: center;
    transition: transform 1s;
}

