
.hand{
    transform-origin: -5%;
    animation: hand-move 3s linear;
    transform-origin: top;
    animation-fill-mode: forwards;
/* transition: transform .5s ; */
transform-box: fill-box;
}

.flutter{
    animation: flutter 3s linear;
    transform-origin:(20%, 20%);
}
.apple{
    animation: apple 3s linear;
    transform-origin:center;
    transform-box: fill-box;

}
@keyframes hand-move {

    30%{
        transform: rotateZ(150deg) ;


    }
    40%{
        transform: rotateZ(140deg) ;

    }

    50%{
        transform: rotateZ(150deg) ;

    }
    60%{
        transform: rotateZ(140deg);

    }
    100%{
        transform: rotateZ(0);
    }
}

@keyframes flutter {
    0%{
        opacity: 0;
    }

    50%{
        opacity: 0.6;
    }
    60%{
        opacity: 0;
    }

    100%{
        opacity: 1;
    }

}

@keyframes apple {
    0%{
        transform: scale(1) rotateZ(0deg);
    }

    50%{
        transform: scale(1.5) rotateZ(-30deg);
    }
    100%{
        transform: scale(1) rotateX(0deg);
    }

}
    
